import Link from 'next/link';
import { Event } from '@gn/db';
import { EventWithBrand } from '@gn/core/type';
import { formatEventDate } from '@gn/core/event/utils';
import { EventTab } from '@gn/core/type';
import { getEventLink } from '../../events/utils';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Mousewheel } from 'swiper';
import { getResizedCloudinaryImage } from '@gn/core/integrations/cloudinary';
import { useContext, useId } from 'react';
import cn from 'classnames';
import { MultipassCard } from './MultipassCard';
import { CurrentUserContext } from './CurrentUserContext';
import { isMultipassValid } from '@gn/core/multipass/utils';
import { NewTabIcon } from './icons/NewTabIcon';

function EventSlide({ event, highPrio, defaultEventTab, brandDomainUrl }) {
    const user = useContext(CurrentUserContext);

    let eventTab = defaultEventTab;
    const isUpcomingEvent = event.startDate > new Date();
    const isMultipassHolder = !!user && isMultipassValid(user);
    const shouldOpenConferencePage = !isMultipassHolder && brandDomainUrl;

    if ((eventTab === EventTab.SPEAKERS && isUpcomingEvent) || (eventTab === EventTab.TALKS && !isUpcomingEvent)) {
        eventTab = '';
    }
    if (eventTab === EventTab.WORKSHOPS) {
        eventTab = '?filter=workshops';
    }

    const url = shouldOpenConferencePage
        ? event.brand.domain
        : eventTab
        ? `${getEventLink(event)}/${eventTab}`
        : getEventLink(event);
    return (
        <SwiperSlide className="swiper-slide">
            <div
                className={cn('card-event', {
                    'card-event--promoted': event.isPromoted,
                })}
            >
                <Link
                    href={url}
                    target={shouldOpenConferencePage ? '_blank' : undefined}
                    className="card-event__link"
                />
                <div className="card-event__body">
                    <div className="card-event__img">
                        <img
                            // @ts-ignore
                            fetchpriority={highPrio ? 'high' : 'low'}
                            src={getResizedCloudinaryImage(event.brand.icon) || ''}
                            alt={event.name}
                        />
                    </div>
                    <div className="card-event__title">
                        {event.name}
                        {shouldOpenConferencePage && <NewTabIcon className="icon-new-tab" />}
                    </div>
                    <div className="card-event__date">{formatEventDate({ ...event, short: true })}</div>
                </div>
            </div>
        </SwiperSlide>
    );
}

function MultipassCardSlide() {
    const currentUser = useContext(CurrentUserContext);

    if (!currentUser || (currentUser && !isMultipassValid(currentUser))) {
        return (
            <SwiperSlide className="swiper-slide">
                <MultipassCard asLink />
            </SwiperSlide>
        );
    }
    return null;
}

export function EventsSwiper({
    events,
    defaultEventTab,
    withMultipassCard,
    brandDomainUrl,
}: {
    events: (Event | EventWithBrand)[];
    defaultEventTab?: EventTab;
    withMultipassCard?: boolean;
    brandDomainUrl?: boolean;
}) {
    const id = useId();
    const uniqueId = id.substring(1, id.length - 1);
    SwiperCore.use([Navigation, Mousewheel]);
    return (
        <div className="events-swiper">
            <Swiper
                threshold={12}
                loop={false}
                slidesPerView="auto"
                spaceBetween={0}
                direction="horizontal"
                mousewheel={{
                    forceToAxis: true,
                    thresholdDelta: 12,
                }}
                watchOverflow={true}
                className="swiper-container"
                navigation={{
                    nextEl: `.events-swiper__next--${uniqueId}`,
                    prevEl: `.events-swiper__prev--${uniqueId}`,
                }}
            >
                <div className="swiper-wrapper">
                    {withMultipassCard && <MultipassCardSlide />}
                    {events.map((event, index) => (
                        <EventSlide
                            key={event.id}
                            event={event}
                            highPrio={index < 3}
                            defaultEventTab={defaultEventTab}
                            brandDomainUrl={brandDomainUrl}
                        />
                    ))}
                </div>
            </Swiper>
            <div className={`swiper-button-prev events-swiper__prev--${uniqueId} swiper-button-disabled`}>
                <svg className="icon icon-arrow-left">
                    <use xlinkHref="/img/sprite.svg?v3#arrow-left" />
                </svg>
            </div>
            <div className={`swiper-button-next events-swiper__next--${uniqueId}`}>
                <svg className="icon icon-arrow-right">
                    <use xlinkHref="/img/sprite.svg?v3#arrow-right" />
                </svg>
            </div>
        </div>
    );
}
