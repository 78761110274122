import TranslateIcon from '../icons/TranslateIcon';
import { useState } from 'react';
import { Lang, LANG_VERSION_TITLES } from '@gn/core/localization';
import { getLocalizationUrl } from '@gn/core/utils/url';
import Link from 'next/link';
import { useRouterQuery } from '@blitzjs/next';

type LocalizationType = {
    slug: string;
    lang: string;
    title: string | null;
};

interface LocalizationSelectorProps {
    localizations: LocalizationType[];
    lang: Lang | string;
    type?: 'links';
    onChange?: (lang: Lang) => void;
}

const LocalizationList = ({ localizations, lang, onChange }: LocalizationSelectorProps) => {
    const { filter } = useRouterQuery();

    return (
        <ul>
            {localizations?.map((l) => (
                <li key={l.lang}>
                    {onChange ? (
                        <p className={lang === l.lang ? '_active' : undefined} onClick={() => onChange(l.lang as Lang)}>
                            [{l.lang}] {l.title}
                        </p>
                    ) : (
                        <Link
                            href={`${getLocalizationUrl(l)}${filter ? `?filter=${filter}` : ''}`}
                            className={lang === l.lang ? '_active' : undefined}
                            scroll={false}
                        >
                            [{l.lang}] {l.title}
                        </Link>
                    )}
                </li>
            ))}
        </ul>
    );
};

const LocalizationSelector = (props: LocalizationSelectorProps) => {
    const [openLangDropdown, setOpenLangDropdown] = useState(false);

    return props.type === 'links' ? (
        <div className="language-wrap">
            <h5 className="mb-3">Available in other languages:</h5>
            <LocalizationList {...props} />
        </div>
    ) : (
        <div
            className={`form-select ${openLangDropdown ? '_open' : ''}`}
            onClick={() => setOpenLangDropdown((open) => !open)}
        >
            <div className="form-select__head">
                <div className="form-select__text">
                    <TranslateIcon />
                    <span className="hidden-xl">{LANG_VERSION_TITLES[props.lang] || props.lang}</span>
                    <span className="visible-xl">{Lang[props.lang] || props.lang}</span>
                </div>
            </div>
            <div className="form-select__drop">
                <LocalizationList {...props} />
            </div>
        </div>
    );
};

export default LocalizationSelector;
