import { memo } from 'react';
import { useQuery } from '@blitzjs/rpc';
import getPublishedContent from '@gn/core/content/queries/getPublishedContent';
import { basedOnInterestsTitle } from 'app/contents/contentConfig';
import { ContentCategory } from '@gn/core/type';
import { ContentSlider } from './ContentSlider';
import useLocalizedContents from '../hooks/useLocalizedContents';

interface BasedOnYourInterestsSliderProps {
    currentUser: any;
    interests?: number[];
    idsToIgnore: number[];
    categories?: ContentCategory[];
    link?: JSX.Element;
}

function BasedOnYourInterestsSliderComponent({
    interests,
    idsToIgnore,
    categories,
    link,
}: BasedOnYourInterestsSliderProps) {
    const [publishedContent] = useQuery(getPublishedContent, {
        extendWhere: {
            tags: {
                some: {
                    id: {
                        in: interests,
                    },
                },
            },
            id: {
                not: {
                    in: idsToIgnore,
                },
            },
        },
        orderBy: [{ featured: 'asc' }, { endDate: 'desc' }, { views: 'desc' }],
        take: 25,
        categories,
    });

    return publishedContent?.contents ? <WithLocalizations contents={publishedContent.contents} link={link} /> : null;
}

const WithLocalizations = ({ contents, link }) => {
    const localizedContents = useLocalizedContents({ contents });

    return localizedContents.length > 0 ? (
        <ContentSlider
            contents={localizedContents}
            title={basedOnInterestsTitle}
            messageWhenEmpty="Please subscribe to some tags to get personalized content recommendations."
            link={link}
        />
    ) : null;
};

export const BasedOnYourInterestsSlider = memo(BasedOnYourInterestsSliderComponent, (prev, next) => {
    return prev.currentUser.id === next.currentUser.id;
});
