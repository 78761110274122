import { Lang } from '@gn/core/localization';
import { createContext, PropsWithChildren, useContext, useState } from 'react';

type LanguageContextValue = {
    language: Lang;
    setLanguage: (language: Lang) => void;
};

export const LanguageContext = createContext({} as LanguageContextValue);

export const LanguageProvider = ({
    children,
    defaultLanguage = Lang.EN,
}: PropsWithChildren<{ defaultLanguage?: Lang }>) => {
    const [language, setLanguage] = useState(defaultLanguage);

    return <LanguageContext.Provider value={{ language, setLanguage }}>{children}</LanguageContext.Provider>;
};

export const useLanguage = () => {
    const context = useContext(LanguageContext);
    if (!context) {
        throw new Error('useLanguage must be used within a LanguageProvider');
    }
    return context;
};
