export default function TranslateIcon() {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-translate icon-md"
        >
            <path
                d="M2.25 3.75H11.25M6.75 2.25V3.75M7.53614 10.875C6.39062 9.68834 5.46055 8.29234 4.8089 6.75M9.375 13.5H14.625M8.25 15.75L12 8.25L15.75 15.75M9.56334 3.75C8.8373 8.07767 6.05227 11.7072 2.25 13.5967"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
