import { useQuery } from '@blitzjs/rpc';
import { Lang } from '@gn/core/localization';
import type { Content } from '@gn/db/client';
import getContentsLocalization from 'app/contents/queries/getContentsLocalization';
import { useLanguage } from '../context/LanguageContext';

const FETCH_LOCALIZATIONS_STALE_TIME = 5 * 60 * 1000;

export default function useLocalizedContents<T extends Pick<Content, 'id' | 'title' | 'summary' | 'text' | 'slug'>>({
    contents,
}: {
    contents: T[];
}) {
    const { language } = useLanguage();

    const [data] = useQuery(
        getContentsLocalization,
        { contentIds: contents.map((c) => c.id), language },
        { enabled: !!language && language !== Lang.EN, staleTime: FETCH_LOCALIZATIONS_STALE_TIME },
    );

    return data
        ? contents.map((content) => ({
              ...content,
              title: data[content.id]?.title || content.title,
              summary: data[content.id]?.summary || content.summary,
              text: data[content.id]?.text || content.text,
              slug: data[content.id]?.slug || content.slug,
          }))
        : contents;
}
